import styles from './styles.module.css';

export const ScannedArtworks = (props) => {
  return (
    <div className={styles.main}>
      <div className={`${props.invalid ? styles.artworkError : styles.artwork}`}>
        <h3 className='m0'>{props.lastScanned} {props.invalid ? ' - '+props.reason : ''}</h3>
        <small>{props.date}</small>
      </div>
      <div className='m-b-30 m-t-20'>
        <img className={styles.fullSizeImage} src={props.imageUrl} alt='' />
      </div>
    </div>
  );
};
