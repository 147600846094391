import { useContext, useState, useEffect } from "react";
import AppContext from 'context/app-context';
import axios from "axios";
import styles from './styles.module.css';
import BarcodeReader from 'react-barcode-reader';
import barcodeGlow from '../../../assets/images/barcode-glow.png'; 

export const ArtworkStocktakeHandheld = () => {
  const [isLoading, setIsloading] = useState(false);
  const { getLocationLastArtwork, currentStocktake, currentLocation, setScanning } = useContext(AppContext);
  const [scannerLoading, setScannerLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const [scannerValue, setScannerValue] = useState("");
  const [message, setMessage] = useState('');

  document.onkeydown = function (t) {
    if(t.which === 9){
      return false;
    }
  }
  
  const onKeyDetect = (result) => {
    setMessage('Scanning')
  }

  const handleScan = (result) => {
    setMessage(result)
    setScannerValue(result)
  }

  const handleError = (err) => {
    setMessage(err)
    setScannerValue(err)
  }

  useEffect(() => {
    if (!initialised) {
      setInitialised(true);
      setScanning(true);
    }
  }, [
    initialised, 
    setScanning, 
    setInitialised
  ]);

  useEffect(() => {
    const onResult = setTimeout(async () => {

        if (!isLoading && scannerValue) {
          setIsloading(true);
          setScannerLoading(true);
          setError('');
          setScanning(false);
          try {
            const { data } = await axios.post(`stocktakes/${currentStocktake.id}/locations/${currentLocation.id}/artworks`, {
                barcode: scannerValue,
                count: 1
            });
            
            setTimeout(() => {
              getLocationLastArtwork(currentStocktake.id, currentLocation.id);
              if(!data.success) {
                setError('Invalid Artwork barcode.');
              } else {
                setSuccess('Artwork Scanned successfully.')
              }
              setScanning(true);
              setIsloading(false);
              setScannerLoading(false);
              setScannerValue('')
              setMessage('')
            }, 1000);
          } catch (e) {
            setError('Something went wrong please try again.');
            console.log(e);
          } 
        }
    }, 500)

    return () => clearTimeout(onResult)
  }, [
    scannerValue, 
    currentStocktake, 
    currentLocation, 
    isLoading, 
    getLocationLastArtwork, 
    setScanning
  ])

  return (
      <div className="text-center">
        <div className={styles.barcodeContainer}>
          <BarcodeReader
            onError={handleError}
            onScan={handleScan}
            timeBeforeScanTest={500}
            onKeyDetect={onKeyDetect}
            />
          <img className={styles.image} src={barcodeGlow} alt=''/>
          { message ? <div className={styles.scan}><p>{message}</p></div> : ''}
        </div>
        {scannerLoading ? <p className={styles.message}>Scanning artwork, please wait...</p> : ''}
        { error ? <p className={styles.error}>{error}</p> : ''}
        { success ? <p className={styles.success}>{success}</p> : ''}
    </div>
  );
}