// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_artwork__1XwWi {\n  position: relative;\n  background: #fff;\n  width: 100%;\n  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);\n  border-radius: 12px;\n  padding: 8px 10px;\n  margin: 5px 0;\n  cursor: pointer;\n  text-align: left;\n}\n.styles_image__aCQh\\+ {\n  margin: 0;\n  position: absolute;\n  top: 50%;\n  right: 15px;\n  transform: translateY(-50%);\n  height: 100%;\n  padding: 5px;\n}\n\n.styles_picked__Xa-vH {\n  background: #ccf9d1;\n}\n\n.styles_error__OD6bK {\n  color: red;\n}", "",{"version":3,"sources":["webpack://./src/components/ArtworkMovement/Artworks/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,2CAA2C;EAC3C,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,SAAS;EACT,kBAAkB;EAClB,QAAQ;EACR,WAAW;EAEX,2BAA2B;EAC3B,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".artwork {\n  position: relative;\n  background: #fff;\n  width: 100%;\n  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);\n  border-radius: 12px;\n  padding: 8px 10px;\n  margin: 5px 0;\n  cursor: pointer;\n  text-align: left;\n}\n.image {\n  margin: 0;\n  position: absolute;\n  top: 50%;\n  right: 15px;\n  -ms-transform: translateY(-50%);\n  transform: translateY(-50%);\n  height: 100%;\n  padding: 5px;\n}\n\n.picked {\n  background: #ccf9d1;\n}\n\n.error {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"artwork": "styles_artwork__1XwWi",
	"image": "styles_image__aCQh+",
	"picked": "styles_picked__Xa-vH",
	"error": "styles_error__OD6bK"
};
export default ___CSS_LOADER_EXPORT___;
