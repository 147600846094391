// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_main__MWiCe {\n}\n.styles_stocktakeScroller__pU\\+Ag {\n  min-height: calc(100vh - 240px);\n}\n.styles_locationScroller__Xv-kI {\n  min-height: calc(100vh - 330px);\n}\n.styles_goBack__WsXu9 {\n  position: absolute;\n  left: 15px;\n  top: 109px;\n  font-size: 41px;\n  cursor: pointer;\n}\n.styles_loader__euTz4 {\n  margin-top: 50px;\n  margin-bottom: 20px;\n}\n.styles_loader__euTz4 svg{\n  flex: 1 1;\n  align-self:center;\n}", "",{"version":3,"sources":["webpack://./src/components/Stocktakes/Submitted/styles.module.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,+BAA+B;AACjC;AACA;EACE,+BAA+B;AACjC;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,eAAe;EACf,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,SAAO;EACP,iBAAiB;AACnB","sourcesContent":[".main {\n}\n.stocktakeScroller {\n  min-height: calc(100vh - 240px);\n}\n.locationScroller {\n  min-height: calc(100vh - 330px);\n}\n.goBack {\n  position: absolute;\n  left: 15px;\n  top: 109px;\n  font-size: 41px;\n  cursor: pointer;\n}\n.loader {\n  margin-top: 50px;\n  margin-bottom: 20px;\n}\n.loader svg{\n  flex: 1;\n  align-self:center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles_main__MWiCe",
	"stocktakeScroller": "styles_stocktakeScroller__pU+Ag",
	"locationScroller": "styles_locationScroller__Xv-kI",
	"goBack": "styles_goBack__WsXu9",
	"loader": "styles_loader__euTz4"
};
export default ___CSS_LOADER_EXPORT___;
