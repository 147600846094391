import styles from './styles.module.css';
import { Logo, ArtworkQrScanner, ScannedArtworks } from 'components';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
// import { GoDiffAdded } from 'react-icons/go';
import { GiPauseButton } from 'react-icons/gi';
import { FiCheck } from 'react-icons/fi';
import AppContext from 'context/app-context';
import { useContext, useEffect } from 'react';
import { ArtworkStocktakeHandheld } from 'components/Stocktakes/ArtworkStocktakeHandheld';

export const AddArtworks = () => {
  const navigate = useNavigate();
  const { currentLocation, currentStocktake, lastArtwork, getLocationLastArtwork, setScanning, handheldScanner} = useContext(AppContext);

  useEffect(() => {
    getLocationLastArtwork(currentStocktake.id, currentLocation.id);
  }, [currentLocation]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.main}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className='d-flex align-items-center justify-content-center p-relative p-b-20'>
        <BsArrowLeftCircle
          className={styles.goBack}
          onClick={e => { setScanning(false); navigate('/stocktakes') }}
        />
        <small className='artwork-breadbrumbs'>{currentStocktake?.name} {'>'} {currentLocation?.name} {'>'} Scan Artwork</small>
      </div>

      <div className={styles.scanContainer}>
        {handheldScanner ?
          (
            <ArtworkStocktakeHandheld/>
          ) : (
            <ArtworkQrScanner/>
          )
        }
        {lastArtwork ?
          <ScannedArtworks
            id={lastArtwork.id}
            lastScanned={lastArtwork.collector_serial}
            imageUrl={lastArtwork.file}
            date={lastArtwork.scanned_at}
            invalid={lastArtwork.invalid}
            reason={lastArtwork.reason}
          />
          : 'No Artworks Scanned'
        }
      </div>
      <div className='m-t-30 d-flex justify-content-center'>
        <button
          type='button'
          className='btn btn-icon m-l-5 m-r-5 m-b-30'
          onClick={e => { setScanning(false); navigate('/stocktakes') }}
        >
          <GiPauseButton className='icon' />
        </button>
        <button
          type='button'
          className='btn btn-icon btn-green m-l-5 m-r-5 m-b-30'
          onClick={e => { setScanning(false); navigate('/stocktakes/add-artworks/success') }}
        >
          <FiCheck className='icon' />
        </button>
      </div>
    </div>
  );
};
