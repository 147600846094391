import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import React, { useContext } from 'react';
import { MdError, MdCancel } from 'react-icons/md';
import { Logo } from 'components';
import AppContext from 'context/app-context';

export const SubLocationMoveSubmit = () => {
  const navigate = useNavigate();
  const { 
    activeSubLocationMovement,
    receivingLocation,
    receivingSubLocation,
    receivingSubLocationFullPath,
    submitSubLocationMovement,
    moveableArtworkCount
  } = useContext(AppContext);

  const markComplete = async () => {
    await submitSubLocationMovement();
  };

  return (
    <div className={styles.main}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className='d-flex align-items-center justify-content-center p-relative p-b-30'>
        <BsArrowLeftCircle
          className={styles.goBack}
          onClick={() => navigate('/sublocation-movement/artworks')}
        />
        <h4>{activeSubLocationMovement?.reason}</h4>
      </div>

      <div className='card'>
        <div className={styles.iconContainer}>
          <MdError className={styles.icon} />
        </div>
        <div className='text-center p-b-50'>
          <p>
            Are you sure you want to move {moveableArtworkCount} artwork{moveableArtworkCount > 1 ? 's' : ''} to {receivingLocation.name} {receivingSubLocationFullPath} {'>'} {receivingSubLocation.name}
          </p>
          <p>
            <strong>Artworks with error will not be moved.</strong>
          </p>
        </div>
      </div>
      <div className='m-b-30 m-t-30 d-flex justify-content-center'>
        <button
          type='button'
          className='btn btn-icon btn-green m-l-5 m-r-5 p-l-30 p-r-30'
          onClick={() => markComplete()}
        >
          Submit
        </button>
        <button
          type='button'
          className='btn btn-icon btn-red m-l-5 m-r-5'
          onClick={() => navigate('/sublocation-movement/artworks')}
        >
          <MdCancel className='icon' />
        </button>
      </div>
    </div>
  );
};
