// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_error__aGzyb {\n    color: red;\n}\n.styles_success__kfnyI {\n    color: green;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SubLocation/ArtworkScanner/styles.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,YAAY;AAChB","sourcesContent":[".error {\n    color: red;\n}\n.success {\n    color: green;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "styles_error__aGzyb",
	"success": "styles_success__kfnyI"
};
export default ___CSS_LOADER_EXPORT___;
