import styles from './styles.module.css';
import { Logo, AppTabs } from 'components';
import { useContext, useEffect } from 'react';
import appContext from 'context/app-context';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';

export const Stocktakes = () => {

  const navigate = useNavigate();
  const { token, getStocktakes } = useContext(appContext);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
    const storedToken = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
    getStocktakes(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className={styles.main}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <AppTabs />
    </div>
  );
};
