import styles from './styles.module.css';
import { Logo } from 'components';
import { useContext, useEffect, useState } from 'react';
import appContext from 'context/app-context';
import axios from 'axios';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Move } from 'components/SubLocation/Move';

export const SubLocationMove = () => {

  const { token, getActiveSubLocationMovement, activeSubLocationMovement } = useContext(appContext);
  const [movementChecked, setMovementChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
    
    setTimeout(() => {
      if (!movementChecked) {
        getActiveSubLocationMovement();
        setMovementChecked(true);
      }
    }, 1000);

    if(activeSubLocationMovement) {
      navigate('/sublocation-movement/artworks');
    }

    const storedToken = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;

  }, [token, activeSubLocationMovement, getActiveSubLocationMovement, movementChecked, navigate]);

  return (
    <div className={styles.main}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className='d-flex align-items-center justify-content-center p-relative p-b-10'>
        <BsArrowLeftCircle
          className={styles.goBack}
          onClick={() => navigate('/')}
        />
        <h4>Sub-Location Movement</h4>
      </div>
      <Move />
    </div>
  );
};
