import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import React, { useContext } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { Logo } from 'components';
import AppContext from 'context/app-context';

export const CatalogueSuccess = () => {
  const navigate = useNavigate();
  const { catalogue } = useContext(AppContext);

  return (
    <div className={styles.main}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className='d-flex align-items-center justify-content-center p-relative p-b-30'>
        <BsArrowLeftCircle
          className={styles.goBack}
          onClick={() => navigate('/catalogue-stockcheck')}
        />
        <h4>{catalogue?.title}</h4>
      </div>

      <div className='card'>
        <div className={styles.iconContainer}>
          <MdCheckCircle className={styles.icon} />
        </div>
        <div className='text-center p-b-50'>
          <p>
            Catalogue Stock Check for
            <strong> {catalogue?.title} </strong>
            has successfully been submitted.
          </p>
        </div>
      </div>
    </div>
  );
};
