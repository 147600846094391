import styles from './styles.module.css';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import AppContext from 'context/app-context';
import { useContext } from 'react';

export const Location = (props) => {
  const { setCurrentLocation } = useContext(AppContext);

  return (
    <div>
     { props.location?.completed ?
      <div className='radio location' disabled={props.location?.completed}>
        <input type='radio' name='radio1' id={props.id} value={props.id} />
        <label htmlFor={props.id}>
          <div className={styles.stocktakeItem}>
            <h3 className='m0'>{props.location?.name}</h3>
            <small>
              {props.location?.code} | {props.location?.created_at}
            </small>
            {props.location?.completed === 0 ? (
              <BsFillCheckCircleFill className={styles.iconRed} />
            ) : (
              <BsFillCheckCircleFill className={styles.iconGreen} />
            )}
          </div>
        </label>
      </div>
      :
      <div className='radio location' disabled={props.location?.completed}
        onClick={(e) => (setCurrentLocation(props.location))}>
        <input type='radio' name='radio1' id={props.id} value={props.id} />
        <label htmlFor={props.id}>
          <div className={styles.stocktakeItem}>
            <h3 className='m0'>{props.location?.name}</h3>
            <small>
              {props.location?.code} | {props.location?.created_at}
            </small>
            {props.location?.completed === 0 ? (
              <BsFillCheckCircleFill className={styles.iconRed} />
            ) : (
              <BsFillCheckCircleFill className={styles.iconGreen} />
            )}
          </div>
        </label>
      </div>
    }
    </div>
  );
};

export const SearchLocation = () => {

  const { searchLocation } = useContext(AppContext);

  const search = (value) => {
    if (value.length >= 3 || !value.length) {
      setTimeout( async() => {
        await searchLocation(value);
      }, 3000);
    }
  }

  return (
    <div className={styles.searchWrapper}>
      <input
        className={styles.searchInput}
        type='text'
        placeholder='Search...'
        onChange={(e) => search(e.currentTarget.value)}
      />
      <div className={styles.search}></div>
    </div>
  );
};
