import { useContext, useEffect } from 'react';
import styles from './styles.module.css';
import ScrollBox from 'react-responsive-scrollbox';
import { Stocktake } from '../Stocktake/Stocktake';
import AppContext from '../../../context/app-context';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { TailSpin } from  'react-loader-spinner'

export const Submitted = (props) => {
  const { stocktakes, getStocktakes, isLoading } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect( () => {
    async function getSTock() {
      await getStocktakes(1);
    }
    getSTock();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <BsArrowLeftCircle
            className={styles.goBack}
            onClick={() => navigate('/')}
          />
      <div className={styles.main}>
        <TailSpin
            height="80"
            width="80"
            color="#000000"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperClass={styles.loader}
            visible={isLoading}
          />

        {!isLoading ? (
          <ScrollBox className={styles.stocktakeScroller}>
            {stocktakes.length ?
              stocktakes.map((stocktake, i) => (
                <Stocktake
                  key={i}
                  name={stocktake.name}
                  demographic={stocktake.demographic}
                  date={stocktake.created_at}
                  completed={stocktake.stocktake_status_id}
                />
              ))
              : <p className='text-center'>No Stocktakes have been submitted.</p>
            }
          </ScrollBox>
        ) : ''}
      </div>
    </>
  );
};
