import appContext from './app-context';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AppState = ({children}) => {
  const [isAuth, setIsAuth] = useState(false);
  const [token, setToken] = useState('');
  const [user, setUser] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [artworks, setArtworks] = useState([]);
  const [stocktakes, setStocktakes] = useState([]);
  const [currentStocktake, setCurrentStocktake] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [artwork, setArtwork] = useState(null);
  const [locations, setLocations] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [location, setLocation] = useState(null);
  const [locationToAdd, setLocationToAdd] = useState(null);
  const [artworkToAdd, setArtworkToAdd] = useState(null);
  const [lastArtwork, setLastArtwork] = useState(null);
  
  const [approvedArtworkMovements, setApprovedArtworkMovements] = useState([]);
  const [artworkMovement, setArtworkMovement] = useState(null);
  const [artworkMovementArtworks, setArtworkMovementArtworks] = useState([]);
  const [artworkMovementArtworksPicked, setArtworkMovementArtworksPicked] = useState([]);
  const [invalidStockCheckArtworks, setInvalidStockCheckArtworks] = useState([]);
  
  const [reservedCatalogues, setReservedCatalogues] = useState([]);
  const [catalogue, setCatalogue] = useState(null);
  const [catalogueArtworks, setCatalogueArtworks] = useState([]);
  const [catalogueArtworksPicked, setCatalogueArtworksPicked] = useState([]);
  const [invalidCatalogueArtworks, setInvalidCatalogueArtworks] = useState([]);

  const [scanning, setScanning] = useState(false);
  const [subLocationMovementReason, setSubLocationMovementReason] = useState('');
  const [activeSubLocationMovement, setActiveSubLocationMovement] = useState(false);
  const [subLocationMovementArtworks, setSubLocationMovementArtworks] = useState([]);
  const [invalidSubLocationMovementArtworks, setInvalidSubLocationMovementArtworks] = useState([]);
  const [moveableArtworkCount, setMoveableArtworkCount] = useState(0);
  const [receivingLocation, setReceivingLocation] = useState(false);
  const [receivingSubLocation, setReceivingSubLocation] = useState(false);
  const [receivingSubLocationFullPath, setReceivingSubLocationFullPath] = useState(false);

  const [handheldScanner, setHandheldScanner] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function startApp() {
      await getToken();
      await getUser();
      await getUserPermissions();
      const storedToken = localStorage.getItem('token');
      axios.defaults.headers.common["Authorization"] = `Bearer ${storedToken}`;
    }
    startApp();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getUser = async () => {
    try {
      const { data } = await axios.get('auth/user');

       setUser(data);
       setIsAuth(true)
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    }
  }

  const getUserPermissions = async () => {
    try {
      const { data } = await axios.get('auth/permissions');
        setUserPermissions(data);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    }
  }

  const getToken = async () => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }

  const removeToken = () => {
    localStorage.removeItem('token');
    setToken(null);
  }

  const login = async (email, password) => {
    try {
        const { data } = await axios.post('auth/login', {
            email: email,
            password: password
        });

        if (data.status === 200) {
          setUser(data);
          setIsAuth(true);
          localStorage.setItem('token', data.token);
          setToken(data.token);
          setErrorMessage(false)
        } else {
          setErrorMessage(true)
        }
      } catch (e) {
        setErrorMessage('Invalid login credentials')
        console.log(e);
      } 
  }

  const addArtwork = async (barcode) => {
    try {
      const { data } = await axios.post(`stocktakes/${currentStocktake.id}/locations/${currentLocation.id}/artworks`, {
          barcode: barcode,
          count: 1
      });

        setArtwork(data);
        getLocationLastArtwork(currentStocktake.id, currentLocation.id);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getStocktakes = async (completed) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`stocktakes/${completed}`);
      setIsLoading(false);
      setStocktakes(data.stocktakes);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getLocations = async (eventId) => {
    try {
      const { data } = await axios.get(`stocktakes/${eventId}/locations`);

      setLocations(data.locations);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const addLocation = async (code) => {
    try {
      const { data } = await axios.post(`stocktakes/${currentStocktake.id}/locations`, {
        code: code
      });

      setCurrentLocation(data.location);
      setLocation(data.location)
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getSubLocations = async (eventId) => {
    try {
      const { data } = await axios.get(`stocktakes/${eventId}/sub-locations`);

      setSubLocations(data.sub_locations);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getArtworks = async (eventId, locationId) =>{
    try {
      const { data } = await axios.get(`stocktakes/${eventId}/locations/${locationId}/artworks`);

      setArtworks(data.artworks);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getLocationLastArtwork = async (eventId, locationId) => {
    try {
      const { data } = await axios.get(`stocktakes/${eventId}/locations/${locationId}/artworks/last`);

      setLastArtwork(data.artwork);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
    }
  }

  const completeLocation = async () => {
    try {
      await axios.get(`stocktakes/${currentStocktake.id}/locations/${currentLocation.id}/complete`);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const completeStocktake = async () => {
    try {
      await axios.get(`stocktakes/${currentStocktake.id}/complete`);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const searchLocation = async (search) => {
    try {
      const { data } = await axios.get(`stocktakes/${currentStocktake.id}/locations/search?search=${search}`);
      setLocations(data.locations)
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getApprovedArtworkMovements = async () => {
    try {
      const { data } = await axios.get(`artwork-movements/approved`);

      setApprovedArtworkMovements(data.approvedArtworkMovements);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getArtworkMovement = async (briefId) => {
    try {
      const { data } = await axios.get(`artwork-movements/${briefId}/brief`);

      setArtworkMovement(data.artworkMovement);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getArtworkMovementArtworks = async (briefId) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`artwork-movements/${briefId}/artworks`);

      setIsLoading(false);
      setArtworkMovementArtworks(data.artworkMovementArtworks);
      setArtworkMovementArtworksPicked(data.picked);
      setInvalidStockCheckArtworks(data.invalidStockCheckArtworks);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const submitArtworkMovement = async (briefId) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(`artwork-movements/${briefId}/submit`);

      if (data.artworkMovementStockcheck) {
        setIsLoading(false);
        navigate('/artwork-movement-stockcheck/success');
      }
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getReservedCatalogues = async () => {
    try {
      const { data } = await axios.get(`catalogues/reserved`);

      setReservedCatalogues(data.reservedCatalogues);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getCatalogue = async (briefId) => {
    try {
      const { data } = await axios.get(`catalogues/${briefId}/brief`);

      setCatalogue(data.catalogue);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getCatalogueArtworks = async (briefId) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`catalogues/${briefId}/artworks`);

      setIsLoading(false);
      setCatalogueArtworks(data.catalogueArtworks);
      setCatalogueArtworksPicked(data.picked);
      setInvalidCatalogueArtworks(data.invalidCatalogueArtworks);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const submitCatalogue = async (briefId) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(`catalogues/${briefId}/submit`);

      if (data.catalogueStockcheck) {
        setIsLoading(false);
        navigate('/catalogue-stockcheck/success');
      }
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const getActiveSubLocationMovement = async () => {
    try {
      const { data } = await axios.get(`sublocation-movements/active`);

      setActiveSubLocationMovement(data.subLocationMovement);
      setReceivingLocation(data.location);
      setReceivingSubLocation(data.subLocation);
      setReceivingSubLocationFullPath(data.subLocationFullPath);
      getSubLocationMovementArtworks(data.subLocationMovement.id);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
    }
  }

  const getSubLocationMovementArtworks = async (subLocationMovementId) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`sublocation-movements/${subLocationMovementId}/artworks`);

      setIsLoading(false);
      setSubLocationMovementArtworks(data.subLocationMovementArtworks);
      setMoveableArtworkCount(data.moveableArtworkCount);
      setInvalidSubLocationMovementArtworks(data.invalidSubLocationMovementArtworks);
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  const submitSubLocationMovement = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(`sublocation-movements/${activeSubLocationMovement.id}/submit`);

      if (data.subLocationMovement.submitted) {

        setSubLocationMovementReason('');
        setActiveSubLocationMovement(false);
        setSubLocationMovementArtworks([]);
        setInvalidSubLocationMovementArtworks([]);
        
        setIsLoading(false);
        navigate('/sublocation-movement/success');
      }
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }
  
  const deleteSubLocationMovement = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(`sublocation-movements/${activeSubLocationMovement.id}/delete`);

      if (data.subLocationMovement === 'deleted') {

        setSubLocationMovementReason('');
        setActiveSubLocationMovement(false);
        setSubLocationMovementArtworks([]);
        setInvalidSubLocationMovementArtworks([]);
        setMoveableArtworkCount(0);
        setIsLoading(false);
        navigate('/');
      }
    } catch (e) {
      if (e.response.data?.error === 'token_expired') {
        removeToken();
      }
      console.log(e);
    } 
  }

  return (
    <appContext.Provider
      value={{
        // Loading
        isLoading,
        setIsLoading,

        // User
        isAuth,
        user,
        token, 
        login,
        getUser,
        getToken,
        setUser,
        setToken,
        errorMessage,
        userPermissions,
        
        // Scanner
        setScanning,
        scanning,
        
        // Locations
        locations,
        subLocations,
        getLocations,
        getSubLocations,
        addLocation,
        currentLocation,
        setCurrentLocation,
        setLocation,
        location,
        locationToAdd,
        completeLocation,
        searchLocation,
        setLocationToAdd,

        // Artworks
        artworks,
        artwork,
        addArtwork, 
        lastArtwork,
        getLocationLastArtwork,
        setArtworkToAdd,
        artworkToAdd,
        getArtworks,

        // Stocktakes
        getStocktakes,
        stocktakes,
        setCurrentStocktake,
        currentStocktake,
        completeStocktake,

        // Artwork Movements
        getApprovedArtworkMovements,
        setApprovedArtworkMovements,
        approvedArtworkMovements,
        getArtworkMovement,
        setArtworkMovement,
        artworkMovement,
        submitArtworkMovement,
        getArtworkMovementArtworks,
        setArtworkMovementArtworks,
        artworkMovementArtworks,
        artworkMovementArtworksPicked, 
        setArtworkMovementArtworksPicked,
        invalidStockCheckArtworks,

        // Catalogues
        getReservedCatalogues,
        setReservedCatalogues,
        reservedCatalogues,
        getCatalogue,
        setCatalogue,
        catalogue,
        submitCatalogue,
        getCatalogueArtworks,
        setCatalogueArtworks,
        catalogueArtworks,
        catalogueArtworksPicked, 
        setCatalogueArtworksPicked,
        invalidCatalogueArtworks,

        // Sublocation Movement
        getActiveSubLocationMovement,
        setActiveSubLocationMovement,
        activeSubLocationMovement,
        setSubLocationMovementReason,
        subLocationMovementReason,
        receivingLocation,
        setReceivingLocation,
        receivingSubLocation,
        setReceivingSubLocation,
        receivingSubLocationFullPath,
        
        getSubLocationMovementArtworks,
        setSubLocationMovementArtworks,
        subLocationMovementArtworks,
        invalidSubLocationMovementArtworks,
        moveableArtworkCount,
        submitSubLocationMovement,
        deleteSubLocationMovement,
        handheldScanner,
        setHandheldScanner
      }}>
      {children}
    </appContext.Provider>
  );
}

export default AppState;
