import styles from './styles.module.css';

export const ArtworkMovementArtworks = (props) => {
  return (
    <div className={styles.main}>
      <div className={`${props.picked ? styles.artwork + ' ' + styles.picked : styles.artwork}`}>
        <h3 className='m0'>{props.barcode}</h3>
          {props.scannedAt ? (
            <small>{props.locationCode} - Last scanned: {props.scannedAt}</small>
          ) : (
            <small>{props.locationCode}</small>
          )}
        <img className={styles.image} src={props.imageUrl} alt='' />
      </div>
    </div>
  );
};
