import styles from './styles.module.css';
import { LocationQrScanner, Logo } from 'components';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { BsArrowLeftCircle } from 'react-icons/bs';
import appContext from 'context/app-context';
import { LocationHandheld } from 'components/Stocktakes/LocationHandheld';

export const AddLocation = () => {
  const navigate = useNavigate();
  const { setScanning, handheldScanner } = useContext(appContext);
  
  return (
    <div className={styles.main}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className='d-flex align-items-center justify-content-center p-relative p-b-10'>
        <BsArrowLeftCircle
          className={styles.goBack}
          onClick={e => { setScanning(false); navigate('/stocktakes') }}
        />
        <h4>Add Location / Sub-Location</h4>
      </div>

      <div className={styles.scanContainer}>
        {handheldScanner ?
          (
            <LocationHandheld/>
          ) : (
            <LocationQrScanner/>
          )
        }
      </div>
    </div>
  );
};
