import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import { AiOutlineEye } from 'react-icons/ai';
import AppContext from '../../../context/app-context';
import axios from 'axios';

export const AuthForm = () => {
  const { token, getToken, setUser, setToken } = useContext(AppContext);

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getToken();
    if (localStorage.getItem('token')) {
      navigate('/');
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [token, getToken, navigate]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post('auth/login', {
        email: email,
        password: password,
      });

      if (data.status === 200) {
        localStorage.setItem('token', data.token);
        setUser(data);
        setToken(data.token);
        getToken();
        setError(false);
        navigate('/');
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
      console.log(e);
    }
  };

  return (
    <div className={styles.main}>
      <form className={styles.form}>
        <div>
          <label className={styles.input}>
            <input
              className={styles.inputField}
              type='email'
              name='email'
              placeholder=' '
              autoFocus={true}
              autoComplete='off'
              onChange={(e) => setEmail(e.currentTarget.value)}
              required
            />
            <span className={styles.inputLabel}>Email</span>
          </label>
        </div>

        <div className='p-t-40'>
          <label className={styles.input}>
            <input
              className={styles.inputField}
              type={passwordShown ? 'text' : 'password'}
              name='password'
              placeholder=' '
              autoComplete='new-password'
              onChange={(e) => setPassword(e.currentTarget.value)}
              required
            />
            <span className={styles.inputLabel}>Password</span>
            <span
              className={styles.togglePasswordVisibility}
              onClick={togglePassword}
            >
              <AiOutlineEye />
            </span>
          </label>
        </div>
        <div className={styles.error}>
          {error ? 'Invalid Login credentials' : ''}
        </div>

        {/* <div className='text-center'>
          <a href='/forgot' className={styles.forgotPass}>
            Forgot password
          </a>
        </div> */}

        <button
          type='submit'
          className='btn btn-block'
          onClick={(e) => loginUser(e)}
        >
          Login
        </button>
      </form>
    </div>
  );
};
