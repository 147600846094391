import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import appContext from 'context/app-context';

export const Dashboard = () => {

  const navigate = useNavigate();
  const { getActiveSubLocationMovement, userPermissions } = useContext(appContext);
  const [movementChecked, setMovementChecked] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (!movementChecked) {
        getActiveSubLocationMovement();
        setMovementChecked(true);
      }
      if (userPermissions.userType === 'admin') {
        setIsAdmin(true)
      }
    }, 1000);
  });

  return (
    <>
    <div className={styles.main}>
        <button
          type='submit'
          className='btn btn-block m-t-20'
          onClick={(e) => navigate('/stocktakes')}
        >
          Stocktake
        </button>
        <button
          type='submit'
          className='btn btn-block m-t-20'
          onClick={(e) => navigate('/sublocation-movement')}
        >
          Sub-Location Movement
        </button>
        
        
        <button
          type='submit'
          className={`btn btn-block m-t-20 ${isAdmin ? '': styles.disabled}`}
          onClick={(e) => navigate('/catalogue-stockcheck')}
          disabled={isAdmin ? false : true}
        >
          Catalogue Stock Check
        </button>


        <button
          type='submit'
          className='btn btn-block m-t-20'
          onClick={(e) => navigate('/artwork-movement-stockcheck')}
        >
          Artwork Movement Stock Check
        </button>
    </div>
    </>
  );
};
