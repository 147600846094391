// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_logo__QeM\\+A {\n  margin: 0 auto;\n  text-align: center;\n}\n.styles_logo__QeM\\+A img {\n  width: 300px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Dashboard/Logo/styles.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,YAAY;AACd","sourcesContent":[".logo {\n  margin: 0 auto;\n  text-align: center;\n}\n.logo img {\n  width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "styles_logo__QeM+A"
};
export default ___CSS_LOADER_EXPORT___;
