import styles from './styles.module.css';
import { Logo } from 'components';
import { Stockcheck } from 'components/ArtworkMovement/Stockcheck';
import { useContext, useEffect } from 'react';
import appContext from 'context/app-context';
import axios from 'axios';
import { BsArrowLeftCircle } from 'react-icons/bs';

import { useNavigate } from 'react-router-dom';

export const ArtworkMovementStockcheck = () => {

  const { token, setScanning } = useContext(appContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
    const storedToken = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;

  }, [token, navigate]);

  return (
    <div className={styles.main}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className='d-flex align-items-center justify-content-center p-relative p-b-10'>
        <BsArrowLeftCircle
          className={styles.goBack}
          onClick={e => { setScanning(false); navigate('/') }}
        />
        <h4>Artwork Movement Stock Check</h4>
      </div>
      <Stockcheck />
    </div>
  );
};
