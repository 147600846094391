import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import React, { useContext } from 'react';
import { GoDiffAdded } from 'react-icons/go';
import { MdCheckCircle } from 'react-icons/md';
import { Logo } from 'components';
import AppContext from 'context/app-context';

export const Success = () => {
  const navigate = useNavigate();
  const { currentLocation } = useContext(AppContext);

  return (
    currentLocation.completed ?
      <div className={styles.main}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className='d-flex align-items-center justify-content-center p-relative p-b-30'>
          <BsArrowLeftCircle
            className={styles.goBack}
            onClick={() => navigate('/stocktakes')}
          />
          <h4>Location Submitted</h4>
        </div>

        <div className='card'>
          <div className={styles.iconContainer}>
            <MdCheckCircle className={styles.icon} />
          </div>
          <div className='text-center p-b-50'>
            <p>Location already submitted, contact Admin to reopen location</p>
          </div>
        </div>

        <button
          type='button'
          className='btn-footer btn m-auto'
          onClick={() => navigate('/stocktakes')}
        >
          <GoDiffAdded className='icon' />
          OK
        </button>
      </div>
    : 
      <div className={styles.main}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className='d-flex align-items-center justify-content-center p-relative p-b-30'>
          <BsArrowLeftCircle
            className={styles.goBack}
            onClick={() => navigate('/stocktakes')}
          />
          <h4>Location created</h4>
        </div>

        <div className='card'>
          <div className={styles.iconContainer}>
            <MdCheckCircle className={styles.icon} />
          </div>
          <div className='text-center p-b-50'>
            <p>
            Location  <strong>{currentLocation?.name} </strong>
              has successfully been added.
            </p>
            <p>You can now proceed to scan artworks.</p>
          </div>
        </div>

        <button
          type='button'
          className='btn-footer btn m-auto'
          onClick={() => navigate('/stocktakes/add-artworks')}
        >
          <GoDiffAdded className='icon' />
          Proceed
        </button>
      </div>
  );
};
