import styles from './styles.module.css';

export const CatalogueInvalidArtworks = (props) => {
  return (
    <div className={styles.main}>
      <div className={styles.artwork}>
        <h3 className='m0'>{props.code}</h3>
          <small>{props.reason}</small>
      </div>
    </div>
  );
};
