// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_main__EWKTS {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n.styles_logo__-bNKo {\n  padding-top: 40px;\n  padding-bottom: 20px;\n}\n.styles_goBack__VwaKt {\n  position: absolute;\n  left: 0;\n  font-size: 41px;\n  cursor: pointer;\n}\n.styles_iconContainer__ZwTQA {\n  position: relative;\n  top: -60px;\n  text-align: center;\n  background-color: #fff;\n  width: 100px;\n  height: 100px;\n  margin: 0 auto;\n  border-radius: 50%;\n}\n.styles_iconContainer__ZwTQA .styles_icon__hev1K {\n  color: #13c39c;\n  font-size: 100px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Stocktakes/AddArtworks/Success/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,OAAO;EACP,eAAe;EACf,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".main {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n.logo {\n  padding-top: 40px;\n  padding-bottom: 20px;\n}\n.goBack {\n  position: absolute;\n  left: 0;\n  font-size: 41px;\n  cursor: pointer;\n}\n.iconContainer {\n  position: relative;\n  top: -60px;\n  text-align: center;\n  background-color: #fff;\n  width: 100px;\n  height: 100px;\n  margin: 0 auto;\n  border-radius: 50%;\n}\n.iconContainer .icon {\n  color: #13c39c;\n  font-size: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles_main__EWKTS",
	"logo": "styles_logo__-bNKo",
	"goBack": "styles_goBack__VwaKt",
	"iconContainer": "styles_iconContainer__ZwTQA",
	"icon": "styles_icon__hev1K"
};
export default ___CSS_LOADER_EXPORT___;
