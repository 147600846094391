// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_main__D3W\\+f {\n  padding: 0 15px;\n}\n.styles_disabled__p6aOP {\n  background: #666;\n  cursor:not-allowed;\n}", "",{"version":3,"sources":["webpack://./src/components/Dashboard/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".main {\n  padding: 0 15px;\n}\n.disabled {\n  background: #666;\n  cursor:not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles_main__D3W+f",
	"disabled": "styles_disabled__p6aOP"
};
export default ___CSS_LOADER_EXPORT___;
