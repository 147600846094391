import { useContext, useState, useRef, useEffect } from "react";
import AppContext from 'context/app-context';
import axios from "axios";
import styles from './styles.module.css';
import Scanner from '../../BarcodeScanner/Scanner';

export const ArtworkQrScanner = () => {
  const { scanning, setScanning, getLocationLastArtwork, currentStocktake, currentLocation } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const scannerRef = useRef(null);
  
  const onResult = async (barcode) => {
    if (!isLoading) {
      setIsLoading(true);
      setError('');
      setScanning(false);
      try {
        const { data } = await axios.post(`stocktakes/${currentStocktake.id}/locations/${currentLocation.id}/artworks`, {
            barcode: barcode,
            count: 1
        });
        
        setTimeout(() => {
          getLocationLastArtwork(currentStocktake.id, currentLocation.id);
          if(!data.success) {
            setError('Invalid Artwork barcode.');
          }
          setScanning(true);
          setIsLoading(false);
        }, 1000);
      } catch (e) {
        setError('Something went wrong please try again.');
        console.log(e);
      } 
    }
  }

  useEffect(() => {
    if (!initialised) {
      setInitialised(true);
      setScanning(true);
    }
  },[initialised,setScanning]);

  return (
      <div className="text-center">
        <div ref={scannerRef} style={{position: 'relative'}} className="scanner-container">
            <canvas className="drawingBuffer" 
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px'
                }} 
            />
            {scanning ? <Scanner 
              scannerRef={scannerRef} 
              onDetected={async (result) => {
                    if (result) await onResult(result);
                  }}
              /> : null}
        </div>
        <p>{isLoading ? 'Saving artwork please wait...' : ''}</p>
        { error ? <p className={styles.error}>{error}</p> : ''}
    </div>
  );
}