// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_error__Pku6I {\n    color: red;\n}", "",{"version":3,"sources":["webpack://./src/components/Stocktakes/ArtworkQrScanner/styles.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd","sourcesContent":[".error {\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "styles_error__Pku6I"
};
export default ___CSS_LOADER_EXPORT___;
