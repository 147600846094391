// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_main__WVOVl {\n  /* height: 100vh;\n  width: 100vw; */\n}\n.styles_whiteBg__pyxdm {\n  background-color: #fff;\n  width: 100vw;\n  position: fixed;\n  left: 0;\n  top: 0;\n  height: 100vh;\n  z-index: 0;\n  overflow: hidden;\n}\n.styles_content__AARZy {\n  position: relative;\n  height: 100vh;\n  z-index: 1;\n  overflow: hidden;\n}\n.styles_logo__LG-DT {\n  padding-top: 70px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Auth/styles.module.css"],"names":[],"mappings":"AAAA;EACE;iBACe;AACjB;AACA;EACE,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,OAAO;EACP,MAAM;EACN,aAAa;EACb,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".main {\n  /* height: 100vh;\n  width: 100vw; */\n}\n.whiteBg {\n  background-color: #fff;\n  width: 100vw;\n  position: fixed;\n  left: 0;\n  top: 0;\n  height: 100vh;\n  z-index: 0;\n  overflow: hidden;\n}\n.content {\n  position: relative;\n  height: 100vh;\n  z-index: 1;\n  overflow: hidden;\n}\n.logo {\n  padding-top: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles_main__WVOVl",
	"whiteBg": "styles_whiteBg__pyxdm",
	"content": "styles_content__AARZy",
	"logo": "styles_logo__LG-DT"
};
export default ___CSS_LOADER_EXPORT___;
