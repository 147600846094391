import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import React, { useContext } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { Logo } from 'components';
import AppContext from 'context/app-context';

export const StocktakeSuccess = () => {
  const navigate = useNavigate();
  const { 
    currentStocktake,
   } = useContext(AppContext);

  return (
    <div className={styles.main}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className='d-flex align-items-center justify-content-center p-relative p-b-30'>
        <BsArrowLeftCircle
          className={styles.goBack}
          onClick={e => { navigate('/stocktakes') }}
        />
        <h4>{currentStocktake?.name}</h4>
      </div>

      <div className='card'>
        <div className={styles.iconContainer}>
          <MdCheckCircle className={styles.icon} />
        </div>
        <div className='text-center p-b-50'>
          <p>
            Successfully submitted {currentStocktake?.name} Stocktake
          </p>
        </div>
      </div>
    </div>
  );
};
