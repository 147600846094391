import styles from './styles.module.css';
import { BsFillCheckCircleFill } from 'react-icons/bs';

export const Stocktake = (props) => {
  return (
    <div className='radio'>
      <input type='radio' name='radio1' id={props.id} value={`${props.id}`} />
      <label htmlFor={`${props.id}`}>
        <div className={styles.stocktakeItem}>
          <h3 className='m0'>{props.name}</h3>
          <small>
            {props.demographic} | {props.date}
          </small>
          {props.completed === 0 ? (
            <BsFillCheckCircleFill className={styles.iconRed} />
          ) : (
            <BsFillCheckCircleFill className={styles.iconGreen} />
          )}
        </div>
      </label>
    </div>
  );
};

export const SelectedStocktake = (props) => {
  return (
    <div>
      <div className={styles.stocktakeItemSelected}>
        <h3 className='m0'>{props.name}</h3>
        <small>
          {props.username} | {props.date}
        </small>
        <small className="m-l-5"><BsFillCheckCircleFill className={styles.icon} /></small>
      </div>
    </div>
  );
};
