import { useContext, useState, useRef, useEffect } from "react";
import AppContext from 'context/app-context';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from './styles.module.css';
import Scanner from '../../BarcodeScanner/Scanner';

export const LocationQrScanner = () => {
  const { scanning, setScanning, currentStocktake, setCurrentLocation, setLocation } = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const scannerRef = useRef(null);
  
  const onResult = async (barcode) => {
    if (!isLoading) {
      setIsLoading(true);
      setError(false);
      setScanning(false);
      try {
        const { data } = await axios.post(`stocktakes/${currentStocktake.id}/locations`, {
          code: barcode
        });
        
        if (data.location) {
          setCurrentLocation(data.location);
          setLocation(data.location);
          setTimeout(() => {
            setIsLoading(false);
            setError(false);
            navigate('/stocktakes/add-location/success');
          }, 3000);
          
        } else {
          setTimeout(() => {
            setIsLoading(false);
            setScanning(true);
            setError(true)
          }, 3000);
          console.log('error present')
        }
      } catch (e) {
        setTimeout(() => {
          setIsLoading(false);
          setScanning(true);
          setError(true)
        }, 3000);
        console.log(e);
      } 
    }
  }

  useEffect(() => {
    if (!initialised) {
      setInitialised(true);
      setScanning(true);
    }
  },[initialised,setScanning]);

  return (
    <div className="text-center">
        <div ref={scannerRef} style={{position: 'relative'}} className="scanner-container">
            <canvas className="drawingBuffer" 
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px'
                }}
                height="480"
                width="640"
            />
            {scanning ? <Scanner 
              scannerRef={scannerRef} 
              onDetected={async (result) => {
                    if (result) await onResult(result);
                  }}
              /> : null}
        </div>
        <p>{isLoading ? 'Saving location please wait...' : ''}</p>
        <p className={styles.error}>{error ? `Invalid Location barcode.` : ''}</p>
    </div>
  )

}