// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_logoutBtn__\\+bPu1 {\n  font-size: 20px;\n  position: absolute;\n  right: 10px;\n  top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Dashboard/LogoutBtn/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX","sourcesContent":[".logoutBtn {\n  font-size: 20px;\n  position: absolute;\n  right: 10px;\n  top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoutBtn": "styles_logoutBtn__+bPu1"
};
export default ___CSS_LOADER_EXPORT___;
