// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_main__XuPkC {\n  position: relative;\n  margin-top: 20px;\n}\n.styles_goBack__5WyJg {\n  position: absolute;\n  top: -55px;\n  font-size: 41px;\n  cursor: pointer;\n}\n.styles_disabled__hJky1 {\n  background: #666;\n  cursor:not-allowed;\n}\n.styles_loader__aX8nD {\n  margin-top: 50px;\n  margin-bottom: 20px;\n}\n.styles_loader__aX8nD svg{\n  flex: 1 1;\n  align-self:center;\n}", "",{"version":3,"sources":["webpack://./src/components/SubLocation/Movement/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,SAAO;EACP,iBAAiB;AACnB","sourcesContent":[".main {\n  position: relative;\n  margin-top: 20px;\n}\n.goBack {\n  position: absolute;\n  top: -55px;\n  font-size: 41px;\n  cursor: pointer;\n}\n.disabled {\n  background: #666;\n  cursor:not-allowed;\n}\n.loader {\n  margin-top: 50px;\n  margin-bottom: 20px;\n}\n.loader svg{\n  flex: 1;\n  align-self:center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles_main__XuPkC",
	"goBack": "styles_goBack__5WyJg",
	"disabled": "styles_disabled__hJky1",
	"loader": "styles_loader__aX8nD"
};
export default ___CSS_LOADER_EXPORT___;
