// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_main__lrqxz {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n.styles_logo__43BnA {\n  padding-top: 40px;\n  padding-bottom: 20px;\n}\n.styles_goBack__oQFN- {\n  position: absolute;\n  left: 0;\n  font-size: 41px;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/pages/SubLocationMove/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,OAAO;EACP,eAAe;EACf,eAAe;AACjB","sourcesContent":[".main {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n.logo {\n  padding-top: 40px;\n  padding-bottom: 20px;\n}\n.goBack {\n  position: absolute;\n  left: 0;\n  font-size: 41px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles_main__lrqxz",
	"logo": "styles_logo__43BnA",
	"goBack": "styles_goBack__oQFN-"
};
export default ___CSS_LOADER_EXPORT___;
