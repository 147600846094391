import React from 'react';
import styles from './styles.module.css';
import { MdLogout } from 'react-icons/md';

export default function LogoutBtn() {

  const logout = async () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  }

  return (
    <>
      <MdLogout onClick={(e) => logout()} className={styles.logoutBtn} />
    </>
  );
}
