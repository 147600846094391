import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import ScrollBox from 'react-responsive-scrollbox';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { GoDiffAdded } from 'react-icons/go';
import { Stocktake, SelectedStocktake } from '../Stocktake/Stocktake';
import { Location, SearchLocation } from 'components';
import AppContext from '../../../context/app-context';
import { TailSpin } from  'react-loader-spinner'

export const InProgress = () => {
  const { 
    stocktakes, 
    getStocktakes, 
    locations, 
    getLocations, 
    setCurrentStocktake, 
    currentStocktake, 
    token, 
    currentLocation,  
    isLoading,
    userPermissions
  } = useContext(AppContext);
  
  const [showStocktake, toggleShow] = useState(true);
  const [selectedSTock, setSelectedStock] = useState(currentStocktake);
  const [btnToggle, setBtnToggle] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getStocktakes(0);
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <BsArrowLeftCircle
        className={styles.goBack}
        onClick={() => showStocktake ? navigate('/') : toggleShow(!showStocktake)}
      />
      <div className={styles.main}>
        <TailSpin
            height="80"
            width="80"
            color="#000000"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperClass={styles.loader}
            visible={isLoading}
          />
        {showStocktake && !isLoading && (
          <ScrollBox className={styles.stocktakeScroller}>
            {stocktakes.length ?
              stocktakes.map((stocktake, i) => (
                <div onClick={
                  (e) => (
                    // eslint-disable-next-line no-sequences
                    toggleShow(!showStocktake),
                    setSelectedStock(stocktake),
                    getLocations(stocktake.id),
                    setCurrentStocktake(stocktake))
                  } 
                key={i}>
                  <Stocktake
                    key={stocktake.id}
                    id={stocktake.id}
                    name={stocktake.name}
                    demographic={stocktake.demographic}
                    date={stocktake.created_at}
                    completed={stocktake.stocktake_status_id}
                  />
                </div>
              ))
              : <p className='text-center'>There are currently no Stocktakes in progress.</p>
            }
          </ScrollBox>
        )}

        {!showStocktake && (
          <div>
            <ScrollBox className={styles.locationScroller}>
              <div onClick={() => setBtnToggle(false)}>
                <SelectedStocktake
                  key={selectedSTock.id}
                  name={selectedSTock?.name}
                  username={selectedSTock?.demographic}
                  date={selectedSTock?.created_at}
                />
              </div>
              <SearchLocation />
              {locations && locations.map((location, i) => (
              location.completed === 1 ?
                  <div key={i} disabled>
                    <Location
                      key={i}
                      id={location.id}
                      location={location}
                      eventId={selectedSTock.id}
                    />
                  </div>
                :
                  <div key={i} onClick={() => setBtnToggle(true)}>
                    <Location
                      key={i}
                      id={location.id}
                      location={location}
                      eventId={selectedSTock.id}
                    />
                  </div>

              ))}
            </ScrollBox>
            <div className='m-b-30 m-t-30 d-flex justify-content-center'>
            {btnToggle && currentLocation ? (
              <button
                type='button'
                className='btn btn-icon m-l-5 m-r-5'
                onClick={() => navigate('/stocktakes/add-artworks')}
              >
                <GoDiffAdded className='icon' />
                <span className='m-l-10'>Proceed</span>
              </button>
            ) : (
              <button
                type='button'
                className='btn btn-icon m-l-5 m-r-5'
                onClick={(e) => navigate('/stocktakes/add-location')}
              >
                <GoDiffAdded className='icon' />
                <span className='m-l-10'>Scan Location</span>
              </button>
            )}

            { userPermissions.type === 'admin' && locations.length && selectedSTock?.ready ?
            <button
                type='button'
                className='btn btn-icon btn-green m-l-5 m-r-5'
                onClick={(e) => navigate('/stocktakes/submit/stocktake')}
              >
                Submit {selectedSTock?.name}
              </button> 
              : ''
            }
            </div>
          </div>
        )}
      </div>
    </>
  );
};
