// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_main__mYXdL {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n.styles_logo__S2NNv {\n  padding-top: 40px;\n  padding-bottom: 20px;\n}\n.styles_goBack__xk-B9 {\n  position: absolute;\n  left: 0;\n  font-size: 41px;\n  cursor: pointer;\n}\n.styles_scanContainer__yi87Z {\n  text-align: center;\n  position: relative;\n  min-height: calc(100vh - 320px);\n}\n\n@media (min-width: 992px) {\n  .styles_scanContainer__yi87Z {\n    max-width: 50%;\n    margin: 0 auto;\n  }\n}\n@media (max-width: 991px) {\n  .styles_scanContainer__yi87Z {\n    max-width: 50%;\n    margin: 0 auto;\n  }\n}\n@media (max-width: 768px) {\n  .styles_scanContainer__yi87Z {\n    max-width: 100%;\n    margin: 0 auto;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Stocktakes/AddArtworks/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,OAAO;EACP,eAAe;EACf,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE;IACE,cAAc;IACd,cAAc;EAChB;AACF;AACA;EACE;IACE,cAAc;IACd,cAAc;EAChB;AACF;AACA;EACE;IACE,eAAe;IACf,cAAc;EAChB;AACF","sourcesContent":[".main {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n.logo {\n  padding-top: 40px;\n  padding-bottom: 20px;\n}\n.goBack {\n  position: absolute;\n  left: 0;\n  font-size: 41px;\n  cursor: pointer;\n}\n.scanContainer {\n  text-align: center;\n  position: relative;\n  min-height: calc(100vh - 320px);\n}\n\n@media (min-width: 992px) {\n  .scanContainer {\n    max-width: 50%;\n    margin: 0 auto;\n  }\n}\n@media (max-width: 991px) {\n  .scanContainer {\n    max-width: 50%;\n    margin: 0 auto;\n  }\n}\n@media (max-width: 768px) {\n  .scanContainer {\n    max-width: 100%;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles_main__mYXdL",
	"logo": "styles_logo__S2NNv",
	"goBack": "styles_goBack__xk-B9",
	"scanContainer": "styles_scanContainer__yi87Z"
};
export default ___CSS_LOADER_EXPORT___;
