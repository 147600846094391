import { useContext, useState, useRef, useEffect } from "react";
import AppContext from 'context/app-context';
import axios from "axios";
import styles from './styles.module.css';
import Scanner from '../../BarcodeScanner/Scanner';

export const CatalogueScanner = () => {
  const { catalogue, getCatalogueArtworks, scanning, setScanning } = useContext(AppContext);
  const [scannerLoading, setScannerLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const scannerRef = useRef(null);
  
  const onResult = async (barcode) => {
    if (!scannerLoading) {
      setScanning(false);
      setError('');
      setSuccess('');
      setTimeout(() => {
        setScannerLoading(true);
      }, 500);
      try {
        const { data } = await axios.post(`catalogues/${catalogue.id}/pick-artwork`, {
            barcode: barcode
        });
        
        getCatalogueArtworks(catalogue.id);
        
        setTimeout(() => {
          if(!data.success) {
            setError(data.message);
          }
          if(data.success) {
            setSuccess(data.message);
          }
          setScanning(true);
          setScannerLoading(false);
        }, 3000);
      } catch (e) {
        setError('Something went wrong please try again.');
        console.log(e);
      } 
    }
  }

  useEffect(() => {
    if (!initialised) {
      setInitialised(true);
      setScanning(true);
    }
  },[initialised,setScanning]);

  return (
      <div className="text-center">
        <div ref={scannerRef} style={{position: 'relative'}} className="scanner-container">
            <canvas className="drawingBuffer" 
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px'
                }} 
            />
            {scanning ? <Scanner 
              scannerRef={scannerRef} 
              onDetected={async (result) => {
                    if (result) await onResult(result);
                  }}
              /> : null}
        </div>
        {scannerLoading ? <p>Scanning artwork, please wait...</p> : ''}
        { error ? <p className={styles.error}>{error}</p> : ''}
        { success ? <p className={styles.success}>{success}</p> : ''}
    </div>
  );
}