// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_main__T5uI6 {\n  position: relative;\n}\n\n.styles_input__9cOWW {\n  width: 100%;\n  padding: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/SubLocation/Move/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;AACf","sourcesContent":[".main {\n  position: relative;\n}\n\n.input {\n  width: 100%;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles_main__T5uI6",
	"input": "styles_input__9cOWW"
};
export default ___CSS_LOADER_EXPORT___;
