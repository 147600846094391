import styles from './styles.module.css';
import image from '../../../assets/images/logo.png';

export const Logo = () => {
  return (
    <div className={styles.logo}>
      <img src={image} alt={''}></img>
    </div>
  );
};
