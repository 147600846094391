import { useContext, useState, useEffect } from "react";
import AppContext from 'context/app-context';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from './styles.module.css';
import BarcodeReader from 'react-barcode-reader';
import barcodeGlow from '../../../assets/images/barcode-glow.png'; 

export const LocationHandheld = () => {
  const { setScanning, currentStocktake, setCurrentLocation, setLocation} = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const [scannerValue, setScannerValue] = useState('');
  const [message, setMessage] = useState('');

  document.onkeydown = function (t) {
    if(t.which === 9){
      return false;
    }
  }
  
  const onKeyDetect = (result) => {
    setMessage('Scanning')
  }

  const handleScan = (result) => {
    setMessage(result)
    setScannerValue(result)
  }

  const handleError = (err) => {
    setMessage(err)
    setScannerValue(err)
  }

  useEffect(() => {
    const onResult = setTimeout(async () => {

        if (!isLoading && scannerValue) {

          setIsLoading(true);
          setError(false);
          setScanning(false);
          try {
            const { data } = await axios.post(`stocktakes/${currentStocktake.id}/locations`, {
              code: scannerValue
            });
            
            if (data.location) {
              setCurrentLocation(data.location);
              setLocation(data.location);
              setTimeout(() => {
                setIsLoading(false);
                setError(false);
                navigate('/stocktakes/add-location/success');
              }, 3000);
              
            } else {
              setTimeout(() => {
                setIsLoading(false);
                setScanning(true);
                setError(true)
                setScannerValue('')
                setMessage('')
              }, 3000);
              console.log('error present')
            }
          } catch (e) {
            setTimeout(() => {
              setIsLoading(false);
              setScanning(true);
              setError(true)
              setScannerValue('')
              setMessage('')
            }, 1000);
            console.log(e);
          } 
        }
    }, 500)

    return () => clearTimeout(onResult)
  }, [
    scannerValue, 
    currentStocktake, 
    isLoading, 
    setScanning,
    navigate,
    setCurrentLocation,
    setLocation
  ])

  useEffect(() => {
    if (!initialised) {
      setInitialised(true);
      setScanning(true);
    }
  }, [
    initialised, 
    setScanning, 
    setInitialised
  ]);

  return (
      <div className="text-center">
        <div className={styles.barcodeContainer}>
          <BarcodeReader
            onError={handleError}
            onScan={handleScan}
            timeBeforeScanTest={500}
            onKeyDetect={onKeyDetect}
            />
          <img className={styles.image} src={barcodeGlow} alt=''/>
          { message ? <div className={styles.scan}><p>{message}</p></div> : ''}
        </div>
        {isLoading ? <p className={styles.message}>Saving location please wait...</p> : ''}
        <p className={styles.error}>{error ? `Invalid Location barcode.` : ''}</p>
    </div>
  );
}