import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import React, { useContext } from 'react';
// import { GoDiffAdded } from 'react-icons/go';
import { MdCheckCircle } from 'react-icons/md';
import { Logo } from 'components';
import AppContext from 'context/app-context';

export const ScannedSuccess = () => {
  const navigate = useNavigate();
  const { currentLocation, completeLocation } = useContext(AppContext);

  const markComplete = async () => {
    await completeLocation();
    navigate('/stocktakes');
  };

  return (
    <div className={styles.main}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className='d-flex align-items-center justify-content-center p-relative p-b-30'>
        <BsArrowLeftCircle
          className={styles.goBack}
          onClick={() => navigate('/stocktakes')}
        />
        <h4>{currentLocation?.name}</h4>
      </div>

      <div className='card'>
        <div className={styles.iconContainer}>
          <MdCheckCircle className={styles.icon} />
        </div>
        <div className='text-center p-b-50'>
          <p>
            Artworks for
            <strong> {currentLocation?.name} </strong>
            has successfully been scanned.
          </p>
          <p>You can now submit the artwork for this location below.</p>
        </div>
      </div>

      <button
        type='button'
        className='btn-footer btn btn-green m-auto'
        onClick={() => markComplete()}
      >
        Submit
      </button>
    </div>
  );
};
