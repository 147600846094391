// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_artwork__Z8372 {\n  position: relative;\n  background: #f7d2d2;\n  width: 100%;\n  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);\n  border-radius: 12px;\n  padding: 8px 10px;\n  margin: 5px 0;\n  cursor: pointer;\n  text-align: left;\n}\n\n.styles_error__pn-e0 {\n  color: red;\n}", "",{"version":3,"sources":["webpack://./src/components/Catalogue/InvalidArtworks/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,2CAA2C;EAC3C,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".artwork {\n  position: relative;\n  background: #f7d2d2;\n  width: 100%;\n  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);\n  border-radius: 12px;\n  padding: 8px 10px;\n  margin: 5px 0;\n  cursor: pointer;\n  text-align: left;\n}\n\n.error {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"artwork": "styles_artwork__Z8372",
	"error": "styles_error__pn-e0"
};
export default ___CSS_LOADER_EXPORT___;
