import { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import AppContext from '../../../context/app-context';
import { SubLocationMoveScanner } from '../SubLocationScanner';
import { SubLocationMoveHandheld } from '../SubLocationMoveHandheld';

export const Move = () => {
  const { token, subLocationMovementReason, setSubLocationMovementReason, handheldScanner } = useContext(AppContext);
  const [scanningLocation, setScanningLocation] = useState(false);
  
  const setReason = (e) => {
    setSubLocationMovementReason(e.target.value);
  }

  useEffect(() => {

  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.main}>
      <form>
        <label>Reason for Movement</label>
        <input className={styles.input} placeholder="Reason for Movement" onChange={ setReason } value={subLocationMovementReason} disabled={(scanningLocation)? "disabled" : ""} ></input>
      </form>
      {!scanningLocation ? (
        <button
          type='submit'
          className='btn btn-block m-t-20'
          onClick={(e) => setScanningLocation(true)}
        >
          Scan Sub-Location
        </button>
      ) :
        <>
          {handheldScanner ?
            (
              <SubLocationMoveHandheld/>
            ) : (
              <SubLocationMoveScanner/>
            )
          }
        </>
      }
    </div>
  );
};
