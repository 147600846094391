import { useContext, useEffect, useRef } from 'react';
import { useNavigate, } from 'react-router-dom';
import styles from './styles.module.css';
import Select from 'react-select';
import AppContext from '../../../context/app-context';
import { CatalogueArtworks } from 'components/Catalogue/Artworks';
import { CatalogueScanner } from 'components/Catalogue/Scanner';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { GiPauseButton } from 'react-icons/gi';
import { FiCheck } from 'react-icons/fi';
import { TailSpin } from  'react-loader-spinner'
import { CatalogueInvalidArtworks } from '../InvalidArtworks/CatalogueInvalidArtworks';
import { CatalogueHandheld } from '../Handheld';

export const Stockcheck = () => {
  const { 
    token, 
    reservedCatalogues, 
    getReservedCatalogues,
    getCatalogue,
    setCatalogue,
    catalogue,
    setCatalogueArtworks,
    getCatalogueArtworks,
    catalogueArtworks,
    invalidCatalogueArtworks,
    catalogueArtworksPicked,
    submitCatalogue,
    isLoading,
    handheldScanner
  } = useContext(AppContext)
  
  const navigate = useNavigate();
  const selectRef = useRef(null);
  const loseFocus = (e) => {
    setTimeout(() => {
      selectRef.current.blur()
    }, 500);
  }
  const selectStyle = {
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? '#ccc' : 'white',
      color: '#333',
      borderBottom: '1px solid #ccc',
      '&:hover': {
        background: '#f2f9fc',
      },
    }),
    control: base => ({
      ...base,
      border: '1px solid #ccc',
      borderRadius: 0, 
      boxShadow: "none",
      '&:hover': {
          border: '1px solid #ccc',
      },
    })
  };

  useEffect(() => {
    setCatalogue(null);
    setCatalogueArtworks([]);
    getReservedCatalogues();
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.main}>
        <Select
          onChange={e => { getCatalogueArtworks(e.value); getCatalogue(e.value); loseFocus(e); }}
          ref={selectRef}
          options={reservedCatalogues}
          placeholder="Select Catalogue"
          styles={selectStyle}
        />
        <TailSpin
          height="80"
          width="80"
          color="#000000"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperClass={styles.loader}
          visible={isLoading}
        />

      {catalogue  ? (
          <>
            <p className='text-center'>Current Location: {catalogue.location_name ? catalogue?.location_name : 'Unknown'} {catalogue.location_code ? '- '+catalogue.location_code : ''}</p>
            
            <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={handheldScanner ? ['1','2'] : ['1']}>
              <AccordionItem uuid='1' onClick={e => { e.target.blur() }}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Barcode Reader
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='scannerAccordion'>
                  {handheldScanner ?
                      (
                        <CatalogueHandheld/>
                      ) : (
                        <CatalogueScanner/>
                      )
                    }
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem uuid='2' onClick={e => { e.target.blur() }}>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                          Artworks
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='artworkAccordion'>
                    {catalogueArtworks.length ?
                      catalogueArtworks.map((artwork, i) => (
                        <CatalogueArtworks
                          id={artwork.id}
                          barcode={artwork.barcode}
                          imageUrl={artwork.filePath}
                          scannedAt={artwork.scannedAt}
                          picked={artwork.picked}
                          locationCode={(artwork.sublocationCode ? artwork.sublocationCode : (artwork.locationCode ? artwork.locationCode : artwork.locationCodeOld))}
                          key={i}
                      />
                      ))
                      : <p className='text-center'>There are currently no Catalogue Artworks.</p>
                    }
                    {invalidCatalogueArtworks.length ?
                      invalidCatalogueArtworks.map((invalidArtwork, i) => (
                        <CatalogueInvalidArtworks
                          code={invalidArtwork.code}
                          reason={invalidArtwork.reason}
                          key={i}
                      />
                      )) : ''
                    }
                  </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
            <div className='m-b-30 m-t-30 d-flex justify-content-center'>
              <button
                type='button'
                className='btn btn-icon m-l-5 m-r-5'
                onClick={() => navigate('/')}
              >
                <GiPauseButton className='icon' />
              </button>
              <button
                type='button'
                className={`btn btn-icon btn-green m-l-5 m-r-5 ${catalogueArtworksPicked.picked === catalogueArtworksPicked.total ? '' : styles.disabled}`}
                onClick={() => submitCatalogue(catalogue.id)}
                disabled={catalogueArtworksPicked.picked === catalogueArtworksPicked.total ? false : true}
              >
                <FiCheck className='icon' />
              </button>
            </div>
          </>
      )
        : <></>
      }

    </div>
  );
};
