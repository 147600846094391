import styles from './styles.module.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Login } from 'pages/Auth/Login';
import { Landing } from 'pages/Landing/Landing';
import { AddLocation } from 'pages/Stocktakes/AddLocation/AddLocation';
import { Stocktakes } from 'pages/Stocktakes/Stocktakes';
import { Success } from 'pages/Stocktakes/AddLocation/Success/Success';
import { AddArtworks } from 'pages/Stocktakes/AddArtworks/AddArtworks';
import { ScannedSuccess } from 'pages/Stocktakes/AddArtworks/Success/Success';
import { Submit } from 'pages/Stocktakes/SubmitStocktake/Submit';
import { ArtworkMovementStockcheck } from 'pages/ArtworkMovementStockcheck/ArtworkMovementStockcheck';
import { CatalogueStockcheck } from 'pages/CatalogueStockcheck/CatalogueStockcheck';
import { CatalogueSuccess } from 'pages/CatalogueStockcheck/Success/Success';
import { SubLocationMove } from 'pages/SubLocationMove/SubLocationMove';
import LogoutBtn from 'components/Dashboard/LogoutBtn/LogoutBtn';
import AppContext from '../../context/app-context';
import { useContext, useEffect } from 'react';
import { SubLocationMoveArtworks } from 'pages/SubLocationMove/Artworks/Artworks';
import { ArtworkMovementSuccess } from 'pages/ArtworkMovementStockcheck/Success/Success';
import { SubLocationMoveSuccess } from 'pages/SubLocationMove/Success/Success';
import { SubLocationMoveSubmit } from 'pages/SubLocationMove/Submit/Submit';
import { StocktakeSuccess } from 'pages/Stocktakes/Success/Success';
import { SubLocationMoveDelete } from 'pages/SubLocationMove/Delete/Delete';

export const App = () => {
  const navigate = useNavigate();
  const { token } = useContext(AppContext);
  const showLogoutButton = window.location.href.includes('login');

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const showLogout = () => {
    if (!showLogoutButton) {
      return <LogoutBtn />;
    } else {
      return null;
    }
  };

  return (
    <div className={styles.main}>
      {showLogout()}
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/login' element={<Login />} />
        {/* Stocktakes */}
        <Route path='/stocktakes' element={<Stocktakes />} />
        <Route path='/stocktakes/add-location' element={<AddLocation />} />
        <Route path='/stocktakes/add-location/success' element={<Success />} />
        <Route path='/stocktakes/add-artworks' element={<AddArtworks />} />
        <Route path='/stocktakes/add-artworks/success' element={<ScannedSuccess />} />
        <Route path='/stocktakes/submit/stocktake' element={<Submit />} />
        <Route path='/stocktakes/success' element={<StocktakeSuccess />} />
        {/* SubLocation Move */}
        <Route path='/sublocation-movement' element={<SubLocationMove />} />
        <Route path='/sublocation-movement/artworks' element={<SubLocationMoveArtworks />} />
        <Route path='/sublocation-movement/submit' element={<SubLocationMoveSubmit />} />
        <Route path='/sublocation-movement/success' element={<SubLocationMoveSuccess />} />
        <Route path='/sublocation-movement/delete' element={<SubLocationMoveDelete />} />
        {/* Catalogue Stockcheck */}
        <Route path='/catalogue-stockcheck' element={<CatalogueStockcheck />} />
        <Route path='/catalogue-stockcheck/success' element={<CatalogueSuccess />} />
        {/* Artwork Movement Stockcheck */}
        <Route path='/artwork-movement-stockcheck' element={<ArtworkMovementStockcheck />} />
        <Route path='/artwork-movement-stockcheck/success' element={<ArtworkMovementSuccess />} />

      </Routes>
    </div>
  );
};
