import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { App } from 'components';
import AppState from './context/AppState';
import axios from 'axios';

const root = ReactDOM.createRoot(document.getElementById('root'));

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;
root.render(
  <BrowserRouter>
    <AppState>
      <App />
    </AppState>
  </BrowserRouter>
);
