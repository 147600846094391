import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import AppContext from '../../../context/app-context';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { GiPauseButton } from 'react-icons/gi';
import { FiCheck, FiTrash } from 'react-icons/fi';
import { SubLocationMovementArtworks } from '../Artworks';
import { SubLocationArtworkScanner } from '../ArtworkScanner';
import { SubLocationInvalidArtworks } from '../InvalidArtworks';
import { ArtworkHandheld } from '../ArtworkHandheld';

export const Movement = () => {
  const { 
    token,
    getActiveSubLocationMovement,
    subLocationMovementArtworks,
    invalidSubLocationMovementArtworks,
    handheldScanner
  } = useContext(AppContext)
  
  const navigate = useNavigate();

  useEffect(() => {
    getActiveSubLocationMovement();
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.main}>
        <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={handheldScanner ? ['1','2'] : ['1']}>
            <AccordionItem uuid='1' onClick={e => { e.target.blur() }}>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Barcode Reader
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className='scannerAccordion'>
                {handheldScanner ?
                      (
                        <ArtworkHandheld/>
                      ) : (
                        <SubLocationArtworkScanner/>
                      )
                    }
            </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid='2' onClick={e => { e.target.blur() }}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Artworks
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='artworkAccordion'>
                {subLocationMovementArtworks.length ?
                    subLocationMovementArtworks.map((artwork, i) => (
                    <SubLocationMovementArtworks
                        id={artwork.artworkId}
                        barcode={artwork.barcode}
                        imageUrl={artwork.filePath}
                        scannedAt={artwork.scanned_at}
                        sublocationCode={artwork.sublocationCode}
                        key={i}
                    />
                    ))
                    : <p className='text-center'>There are currently no Sub-Location Movement Artworks.</p>
                }
                {invalidSubLocationMovementArtworks.length ?
                    invalidSubLocationMovementArtworks.map((invalidArtwork, i) => (
                    <SubLocationInvalidArtworks
                        code={invalidArtwork.code}
                        reason={invalidArtwork.reason}
                        scannedAt={invalidArtwork.scanned_at}
                        key={i}
                    />
                    ))
                    : ''
                }
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        <div className='m-b-30 m-t-30 d-flex justify-content-center'>
            <button
            type='button'
            className='btn btn-icon btn-red m-l-5 m-r-5'
            onClick={() => navigate('/sublocation-movement/delete')}
            >
            <FiTrash className='icon' />
            </button>
            <button
            type='button'
            className='btn btn-icon m-l-5 m-r-5'
            onClick={() => navigate('/')}
            >
            <GiPauseButton className='icon' />
            </button>
            <button
            type='button'
            className={`btn btn-icon btn-green m-l-5 m-r-5 ${subLocationMovementArtworks.length ? '' : styles.disabled}`}
            disabled={subLocationMovementArtworks.length ? false : true}
            onClick={() => navigate('/sublocation-movement/submit')}
            >
            <FiCheck className='icon' />
            </button>
        </div>
    </div>
  );
};
