import styles from './styles.module.css';

export const SubLocationMovementArtworks = (props) => {
  return (
    <div className={styles.main}>
      <div className={`${styles.artwork+ ' ' + styles.match}`}>
        <h3 className='m0'>{props.barcode}</h3>
        <small>{props.sublocationCode} - Last scanned: {props.scannedAt}</small>
        <img className={styles.image} src={props.imageUrl} alt='' />
      </div>
    </div>
  );
};
