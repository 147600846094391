import styles from './styles.module.css';
import { Logo, Dashboard } from 'components';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import appContext from 'context/app-context';
import axios from 'axios';
import "react-toggle/style.css"
import Toggle from 'react-toggle'

export const Landing = () => {
  const { token, getStocktakes, handheldScanner, setHandheldScanner } = useContext(appContext);
  const navigate = useNavigate();

  const toggleChecked = () => {
    setHandheldScanner(value => !value);
  }

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
    const storedToken = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
    getStocktakes(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <div className={styles.main}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <Dashboard />
      <label className={styles.bottom}>
        <Toggle
          icons={false}
          defaultChecked={handheldScanner}
          onChange={toggleChecked} />
          <br/>
        <span className='label-text'>Use Handheld Scanner</span>
      </label>
      </div>
      </>
  );
};
