import { InProgress } from 'components/Stocktakes/InProgress';
import { Submitted } from 'components/Stocktakes/Submitted';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export const AppTabs = () => {
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>In Progress</Tab>
          <Tab>Submitted</Tab>
        </TabList>

        <TabPanel>
          <InProgress />
        </TabPanel>
        <TabPanel>
          <Submitted />
        </TabPanel>
      </Tabs>
    </>
  );
};
