// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_main__1wVGs {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n.styles_logo__ZUh0J {\n  padding-top: 40px;\n  padding-bottom: 20px;\n}\n\n.styles_bottom__zbKuw {\n  position: absolute;\n  bottom: 20px;\n  width: -webkit-fill-available;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/Landing/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;AACpB","sourcesContent":[".main {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n.logo {\n  padding-top: 40px;\n  padding-bottom: 20px;\n}\n\n.bottom {\n  position: absolute;\n  bottom: 20px;\n  width: -webkit-fill-available;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "styles_main__1wVGs",
	"logo": "styles_logo__ZUh0J",
	"bottom": "styles_bottom__zbKuw"
};
export default ___CSS_LOADER_EXPORT___;
