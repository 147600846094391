import { Logo, AuthForm } from 'components';
import styles from './styles.module.css';

export const Login = () => {
  return (
    <div className={styles.main}>
      <div className={styles.whiteBg} />
      <div className={styles.content}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <AuthForm />
      </div>
    </div>
  );
};
